import React from 'react'
import './Navbar.css'
import logoWhite from '../../assets/logo_white.png'
const imagRef = React.createRef();
const navRef = React.createRef();



function Navbar() {
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" ref={navRef}>
            <div className="container">
                <a className="navbar-brand" href="#home"><img className='logo text-white'  src={logoWhite} ref={imagRef} alt='Logo Load & Stay'></img></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <a className="nav-link" href="#searchHotel">Unterkunft suchen</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#createHotel">Unterkunft erfassen</a>
                    </li>
                </ul>
                </div>
            </div>
            </nav>
    </div>
  )
}


window.addEventListener('scroll', () => {
  if(window.scrollY > 56 ){
    navRef.current.classList.add('navbar-scrolled')
  } else if (window.scrollY < 56 ) {
    navRef.current.classList.remove('navbar-scrolled')
  }
})


export default Navbar