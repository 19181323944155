import React, { useState, useEffect } from "react";
import { Rating } from "@mui/material";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./map.css";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
let hotelURL = process.env.REACT_APP_API_ENDPOINT + "/hotel";

function Map() {
  const [hotels, setHotels] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);

  useEffect(() => {
    const fetchHotels = async () => {
      const response = await fetch(hotelURL);
      const data = await response.json();
      setHotels(data);
    };
    fetchHotels();
  }, []);

  function openModal(hotel) {
    setSelectedHotel(hotel);
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  // Add these styles at the top of your component or in your CSS file
const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#212529',
  padding: '20px',
  zIndex: 1000, // Ensure this is higher than other elements
  border: '1px solid #198754',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  width: '80%', // Adjust width as needed
  maxWidth: '600px' // Adjust max width as needed
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999 // Ensure this is lower than the modal but higher than other content
};

const imageInModal = {
  maxWidth: "100%"
};

  return (
    <>
      <MapContainer
        center={[47.36935, 8.2275]}
        zoom={6}
        scrollWheelZoom={true}
        style={{ height: "50vh" }}
      >
        {hotels.map((hotel) => (
          <Marker position={hotel.location.coordinates} key={hotel._id}>
            <Popup>
              <div onClick={() => openModal(hotel)}>
                <img src={hotel.thumbnail} alt={hotel.name} style={{ width: 200 }} />
                <p className="text-primary fs-5">{hotel.name}</p>
              </div>
            </Popup>
          </Marker>
        ))}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>

      {isModalOpen && (
        <div style={modalStyle}>
          <div className="row">
            <div className="col-11">
              <h2 className="text-primary">{selectedHotel.name}</h2>
            </div>
            <div className="col-1">
             <svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="#ffffff" d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>           
            </div>
            <div className="col-12">
              <img src={selectedHotel.thumbnail} alt={selectedHotel.name} style={imageInModal}/>
              <Rating value={selectedHotel.stars} readOnly></Rating>
            </div>
            <div className="row">
              <div className="col-2">

              </div>
              <div className="col-10">

              </div>
            </div>
          
          </div>
          
          {/* You can add more hotel details here */}
        </div>
      )}

      {isModalOpen && <div style={overlayStyle} onClick={closeModal}></div>}
    </>
  );
}

export default Map;
