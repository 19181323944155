import './App.css' 
import { Header, Hotels, AboutUs, AddHotel, Footer } from './containers'

const App = () => {
  return (
    <div> 
        <Header></Header>
        <Hotels></Hotels>
        <AboutUs></AboutUs>
        <AddHotel></AddHotel>
        <Footer></Footer>
    </div>
  )
}

export default App