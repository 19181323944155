import React from 'react'
import { Navbar } from '../../components'
import './Header.css'
import header  from '../../assets/header.jpeg'

function Header() {
  return (
    <div>
        <img className='bg-header' src={header} alt='Header'></img> 
        <Navbar></Navbar>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 position-absolute top-50 start-50 translate-middle'>
                <h1 className='display-1 fw-bolder text-uppercase text-white '>Reisen mit <div className='typed'>dem <span className='text-success'>E-Auto</span></div></h1>
                <div className='fadeIn'>
                    <a className='btn btn-primary btn-lg me-3 mb-1' href='#searchHotel'>Unterkunft suchen</a>
                    <a className='btn btn-primary btn-lg mb-1' href='#addHotel'>Unterkunft erfassen</a>
                </div>
            </div>
          </div>
    </div>
  )
}

export default Header