import React from 'react'
import './search.css'

function Search() {
  return (
    <div>
      <div className='row mb-5'>
        <div className='col-12'>
          <form className="d-flex" role="search">
            <input className="form-control searchInput shadow me-2" type="search" placeholder="Search" aria-label="Search"></input>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Search