import React from 'react'
import './chargertype.css'

function ChargerType(props) {
  return (
    <div>
        <div className='row mb-3'>
            <div className='col-8'>
                <label>
                <img className='form-icon' src={props.icon} alt={props.name} /> {props.name}</label>
            </div>
            <div className='col-4'>
                <input type="number"  pattern="[0-9]*" inputMode="numeric" min="0" max="100" className='form-control' placeholder='0'name={props.id} onChange={props.onChange}/>
            </div>
        </div>
        
    </div>
  )
}

export default ChargerType