import React from 'react'
import './footer.css'

function Footer() {
  
  return (
    <div>
        <footer className="footer bg-primary bg-gradient">
            <div className="container">
                <div className="row text-white">
                    <div className="col-xs-12 col text-center mt-3">

                        <nav>
                            <h3>Links</h3>
                            <ul className=" list-unstyled">
                                <li>
                                    <a className="nav-link text-white" href="/inprint.html">Datenschutz</a>
                                </li>
                                <li>
                                    <a className="nav-link text-white" href="/inprint.html">Impressum</a>
                                </li>
                                <li>
                                    <a className="nav-link text-white" href="/inprint.html">Cookiebestimmungen</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-xs-12 col text-center mt-3">
                        <h3>Social Media</h3>
                        <div className="btn-group">
                            <a className="btn btn-outline-light btn-social" rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UCZvXdU4NvWVbxZRkxNtrfPw"><i className="fab fa-fw fa-youtube"></i></a>
                            <a className="btn btn-outline-light btn-social" rel="noreferrer" target="_blank" href="https://instagram.com/ioniq_5_adventures"><i className="fab fa-fw fa-instagram"></i></a>
                            <a className="btn btn-outline-light btn-social" rel="noreferrer" target="_blank" href="https://www.facebook.com/profile.php?id=100093024068589"><i className="fab fa-fw fa-facebook"></i></a>
                        </div>
                        {/* 
                        <h3 className="mt-2">Mobile Apps</h3>
                        <div className="btn-group">
                            <a href="https://apps.apple.com/ch/app/dmatteh%C3%B6fler/id1574055018"><img height="50px" src="img/appleStore.png" alt='AppStore Logo'></img></a>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        </footer>
        <section className="page-section bg-primary py-4 text-center text-white">
            <div className="">
                <small> Copyright © LoadAndStay.ch {new Date().getFullYear()}</small>
            </div>
        </section>
    </div>
  )
}

export default Footer