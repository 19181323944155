import React from "react";
import "./aboutus.css";

function AboutUs() {
  return (
    <div className=" bg-primary" id="aboutUs">
      <div className="container">
        <div className="row mt-5">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 text-white">
            <section>
              <h1>Unsere Geschichte: Von einer Reise zu einer Mission</h1>
              <p>
                Unsere Mission begann nach dem Kauf unseres ersten
                Elektroautos. Bei der Planung eines Roadtrips nach Apulien
                in Italien stiessen wir auf Skepsis und Horrorgeschichten aus unserem
                Umfeld. Doch wir liessen uns nicht entmutigen. Bei der Recherche
                fiel uns jedoch auf, wie herausfordernd es ist, Unterkünfte mit
                Lademöglichkeiten zu finden – die Informationen waren oft unklar
                oder fehlten ganz. Deshalb beschlossen wir, Abhilfe zu schaffen
                und eine umfangreiche, neutrale Unterkunftssammlung speziell für
                EV-Fahrer zu erstellen.
              </p>

              <h2>Warum Load & Stay?</h2>
              <ul>
                <li>
                  <strong>Umfassend:</strong> Entdecken Sie eine breite Palette
                  von Domizilen, die sich darauf spezialisiert haben, Ihnen nicht
                  nur einen komfortablen Aufenthalt, sondern auch eine
                  Lademöglichkeit für Ihr Fahrzeug zu bieten.
                </li>
                <li>
                  <strong>Unabhängig:</strong> Unsere Plattform ist nicht
                  gesponsert. Profitieren Sie von unvoreingenommenen Bewertungen
                  und Tipps.
                </li>
                <li>
                  <strong>Mitgestalten:</strong> Teilen Sie Ihre eigenen
                  Erfahrungen und helfen Sie so, unsere Sammlung zu erweitern
                  und stets aktuell zu halten.
                </li>
              </ul>

              <h2>Wie es funktioniert:</h2>
              <ol>
                <li>
                  Nutzen Sie unsere intuitive Suchfunktion: Geben Sie Ihren
                  Zielort ein und finden Sie Hotels mit Lademöglichkeiten.
                </li>
                <li>
                  Informieren Sie sich über die jeweiligen Übernachtungsmöglichkeiten und
                  reservieren Sie direkt über die Homepage der Unterkunft.
                </li>
                <li>
                  Nach Ihrem Aufenthalt: Empfehlen Sie weitere erstklassige
                  Unterkünfte mit Lademöglichkeiten und tragen Sie zur Gemeinschaft
                  bei. Egal ob Hotel, Hostel, Camping oder Ferienwohung.
                </li>
              </ol>

              <p>
                Gemeinsam schaffen wir eine wachsende Sammlung von Übernachtungsmöglichkeiten – von
                EV-Fahrern für EV-Fahrer.
              </p>

              <p>
                Wir wünschen Ihnen eine angenehme Reise und freuen uns darauf,
                Ihnen den idealen Ort zum Übernachten und Laden Ihres
                Elektrofahrzeugs vorzustellen!
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
