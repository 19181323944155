import React from "react";
import { Search, Map} from "../../components";
import "./hotels.css";

function Hotels() {

  return (
    <div className="container mt-3 mb-3" id="searchHotel">
      <div className="row mt-5">
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <Search></Search>
        </div>
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
          <Map></Map>
        </div>
      </div>
    </div>
  );
}

export default Hotels;
