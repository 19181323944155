import React from 'react'
import './addhotel.css'
import { useState, useEffect } from 'react'
import { ChargerType } from '../../components/index'
let hotelTypeUrl = process.env.REACT_APP_API_ENDPOINT + "/hoteltype"
let chargerTypeUrl = process.env.REACT_APP_API_ENDPOINT + "/chargertype"

function AddHotel() {
  //const initialRating = parseInt(localStorage.getItem('selectedRating')) || null;
  //const [rating, setRating] = useState(initialRating);
  //const [hotelStar, setHotelStar] = useState(initialRating);
  //const [isReviewVisible, setReviewVisible] = useState(false);

  const [hotelType, setHotelType] = useState([])
  const [chargerType, setChargerType] = useState([])
  const [formErrors, setFormErrors] = useState({});
  useEffect(() => {
    const fetchHotelType = async () => {
      const respond = await fetch(hotelTypeUrl)
      const data = await respond.json()
      setHotelType(data)
    }
    const fetchChargerType = async () => {
      const respond = await fetch(chargerTypeUrl)
      const data = await respond.json()
      setChargerType(data)
    }

    fetchHotelType()
    fetchChargerType()
  }, [])
  
 

  /*const handleRatingChange = (value) => {
    setRating(value);
  };*/
  /*
  const handleHotelStarChange = (value) => {
    setHotelStar(value);
  };*/

  const validateField = (name, value) => {
    let error = "";
    const codingRegex = /^\s*$|[<>{}[\]();="'`\\]/;
    switch (name) {
      case "name":
        if (!value.trim() || codingRegex.test(value)) error = "Dieses Feld darf nicht leer sein!";
        break;
      case "address":
        if (!value.trim() || codingRegex.test(value)) error = "Dieses Feld darf nicht leer sein!";
        break;
      case "city":
        if (!value.trim() || codingRegex.test(value)) error = "Dieses Feld darf nicht leer sein!";
        break;
      case "zipCode":
        if (!value.trim() || codingRegex.test(value)) error = "Dieses Feld darf nicht leer sein!";
        break;
      case "email":
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(value) && value.trim()) error = "Bitte eine korrekte E-Mail Adresse eingeben";
        break;
      case "homepage":
        const homePageRegex = /^https:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^\s]*)?$/;
        if (!homePageRegex.test(value) && value.trim()) error = "Bitte eine korrekte Homepage eingeben";
        break;
      case "phone":
          if (codingRegex.test(value)  && value.trim()) error = "Sonderzeichen sind nicht gestattet";
          break;
      default:
        break;
    }
  
    return error
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const error = validateField(name, value);
    setFormErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };

  const [formData, setFormData] = useState({
    name: '',
    stars: null,
    address: '',
    zipCode: '',
    city: '',
    country: 'CH', // Set default country value
    homepage: '',
    email: '',
    phone: '',
    description: '',
    type: '',
    standingFee: false,
    reviewVisible: false,
    rating: null,
    reviewText: '',
    chargingStations: [],
  });

  const handleFormChange = (event) => {
    const { name, value, files, type, checked } = event.target;
    const fieldValue = type === 'checkbox' ? checked : value;
  
    if (type === "file") {
      if (name === "otherImage"){
        setFormData((prevData) => ({
          ...prevData,
          [name]: [...files]
      }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: files[0]
      }));
      }
      
    } else if (chargerType.some(charger => charger._id === name)) {
      setFormData((prevData) => ({
        ...prevData,
        chargingStations: Array.isArray(prevData.chargingStations) 
          ? [
              ...prevData.chargingStations.filter(station => station.chargerType !== name),
              { chargerType: name, quantity: Number(fieldValue) }
            ]
          : [{ chargerType: name, quantity: Number(fieldValue) }]
      }));
    } else {
      // Handle all other inputs
      setFormData((prevData) => ({
        ...prevData,
        [name]: fieldValue,
      }));
    }
    const error = validateField(name, value);
    setFormErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

     // Validate if any errors exist
    const hasErrors = Object.values(formErrors).some(error => error);
    // Check if all required fields are filled
    const requiredFields = ['name', 'address', 'zipCode', 'city', 'country', 'thumbnail']; // Add more required fields as needed
    const areRequiredFieldsFilled = requiredFields.every(field => formData[field]);
    if (hasErrors && !areRequiredFieldsFilled) {
        alert('Das Formular wurde nicht korrekt ausgefüllt');
        return; // Prevent further execution of the function
    }

    if (!formData.type) {
      alert("Bitte wähle den Unterkunfstyp aus");
      return;
    }

    if (!formData.thumbnail) {
      alert("Bitte lade ein Titelbild hoch");
      return;
    }

    // Append formData to a new FormData instance
    const dataToSend = new FormData();
    for (const key in formData) {
      if (key === 'chargingStations') continue;
      const value = formData[key];
      if (value !== null && value !== undefined) {
        if (key === "otherImage" && Array.isArray(value)) {
          // If the key is "otherImage", append each file individually
          value.forEach((file) => {
            dataToSend.append(key, file);
          });
        } else if (key === "thumbnail" && value instanceof File) {
          // Assuming thumbnail is a single file. Check if the value is a File instance
          dataToSend.append(key, value);
        } else {
          // For all other fields, append the value directly
          dataToSend.append(key, value);
        }
      }
    }
    dataToSend.append('chargingStations', JSON.stringify(formData.chargingStations));
    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "/hotel", {
        method: 'POST',
        body: dataToSend
      });

      const responseData = await response.json();
      console.log('Server response:', responseData);
      // TODO: Check response for success or error and act accordingly
    } catch (error) {
      console.error('There was an error sending the data', error);
    }
};


  
  return (
    <div className='container' id='addHotel'>
        <div className='row mt-5'>
          <div className='col-12'>
            <h1 className='text-primary'>Unterkunft erfassen</h1>
              <form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12'>
                      <div className='row'>
                        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12'>
                          <div className='mb-3'>
                            <input type='text' className={`form-control  ${formErrors.name ? 'text-danger border-danger' : ''}`} name='name' placeholder='Name der Unterkunft *' onChange={handleFormChange} onBlur={handleBlur} required></input>
                            {formErrors.name && <small className="text-danger">{formErrors.name}</small>}
                          </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                          <select name='type' className='form-select mb-3' id='hoteltype' onChange={handleFormChange}>
                                <option value="">Unterkunftstyp auswählen *</option>
                                  {hotelType.map((hoteltype) => {
                                    return (
                                      <option value={hoteltype._id} key={hoteltype._id} id={hoteltype._id}> {hoteltype.name}  </option>
                                    )
                                  })}
                          </select>
                        </div>
                      </div>
                    
                      <div className='mb-3'>
                        <input type='text' className={`form-control  ${formErrors.address ? 'text-danger border-danger' : ''}`} name='address' placeholder='Adresse *' onBlur={handleBlur} onChange={handleFormChange} required></input>
                        {formErrors.address && <small className="text-danger">{formErrors.address}</small>}
                      </div>
                      <div className='mb-3'>
                        <input type='text' pattern="[0-9]*" inputMode="numeric" className={`form-control  ${formErrors.zipCode ? 'text-danger border-danger' : ''}`} onBlur={handleBlur} name='zipCode' placeholder='PLZ *' onChange={handleFormChange} required></input>
                        {formErrors.zipCode && <small className="text-danger">{formErrors.zipCode}</small>}
                      </div>
                      <div className='mb-3'>
                        <input type='text' className={`form-control  ${formErrors.city ? 'text-danger border-danger' : ''}`} name='city' placeholder='Stadt *' onBlur={handleBlur} onChange={handleFormChange} required></input>
                        {formErrors.city && <small className="text-danger">{formErrors.city}</small>}
                      </div>
                    <select id="country" className="form-select mb-3" name="country" onChange={handleFormChange} required>
                                <option value="CH">🇨🇭 Switzerland</option>
                                <option value="AT">🇦🇹 Austria</option>
                                <option value="DE">🇩🇪 Germany</option>
                                <option value="FR">🇫🇷 France</option>
                                <option value="IT">🇮🇹 Italy</option>
                                <option value="nix" disabled></option>
                                <option value="AF">🇦🇫 Afghanistan</option>
                                <option value="AX">🇦🇽 Aland Islands</option>
                                <option value="AL">🇦🇱 Albania</option>
                                <option value="DZ">🇩🇿 Algeria</option>
                                <option value="AS">🇦🇸 American Samoa</option>
                                <option value="AD">🇦🇩 Andorra</option>
                                <option value="AO">🇦🇴 Angola</option>
                                <option value="AI">🇦🇮 Anguilla</option>
                                <option value="AQ">🇦🇶 Antarctica</option>
                                <option value="AG">🇦🇬 Antigua and Barbuda</option>
                                <option value="AR">🇦🇷 Argentina</option>
                                <option value="AM">🇦🇲 Armenia</option>
                                <option value="AW">🇦🇼 Aruba</option>
                                <option value="AU">🇦🇺 Australia</option>
                                <option value="AZ">🇦🇿 Azerbaijan</option>
                                <option value="BS">🇧🇸 Bahamas</option>
                                <option value="BH">🇧🇭 Bahrain</option>
                                <option value="BD">🇧🇩 Bangladesh</option>
                                <option value="BB">🇧🇧 Barbados</option>
                                <option value="BY">🇧🇾 Belarus</option>
                                <option value="BE">🇧🇪 Belgium</option>
                                <option value="BZ">🇧🇿 Belize</option>
                                <option value="BJ">🇧🇯 Benin</option>
                                <option value="BM">🇧🇲 Bermuda</option>
                                <option value="BT">🇧🇹 Bhutan</option>
                                <option value="BO">🇧🇴 Bolivia</option>
                                <option value="BQ">🇧🇶 Bonaire, Sint Eustatius and Saba</option>
                                <option value="BA">🇧🇦 Bosnia and Herzegovina</option>
                                <option value="BW">🇧🇼 Botswana</option>
                                <option value="BV">🇳🇴 Bouvet Island</option>
                                <option value="BR">🇧🇷 Brazil</option>
                                <option value="IO">🇮🇴 British Indian Ocean Territory</option>
                                <option value="BN">🇧🇳 Brunei Darussalam</option>
                                <option value="BG">🇧🇬 Bulgaria</option>
                                <option value="BF">🇧🇫 Burkina Faso</option>
                                <option value="BI">🇧🇮 Burundi</option>
                                <option value="KH">🇰🇭 Cambodia</option>
                                <option value="CM">🇨🇲 Cameroon</option>
                                <option value="CA">🇨🇦 Canada</option>
                                <option value="CV">🇨🇻 Cape Verde</option>
                                <option value="KY">🇰🇾 Cayman Islands</option>
                                <option value="CF">🇨🇫 Central African Republic</option>
                                <option value="TD">🇹🇩 Chad</option>
                                <option value="CL">🇨🇱 Chile</option>
                                <option value="CN">🇨🇳 China</option>
                                <option value="CX">🇨🇽 Christmas Island</option>
                                <option value="CC">🇨🇨 Cocos (Keeling) Islands</option>
                                <option value="CO">🇨🇴 Colombia</option>
                                <option value="KM">🇰🇲 Comoros</option>
                                <option value="CG">🇨🇬 Congo</option>
                                <option value="CD">🇨🇩 Congo, Democratic Republic of the Congo</option>
                                <option value="CK">🇨🇰 Cook Islands</option>
                                <option value="CR">🇨🇷 Costa Rica</option>
                                <option value="CI">🇨🇮 Cote D'Ivoire</option>
                                <option value="HR">🇭🇷 Croatia</option>
                                <option value="CU">🇨🇺 Cuba</option>
                                <option value="CW">🇨🇼 Curacao</option>
                                <option value="CY">🇨🇾 Cyprus</option>
                                <option value="CZ">🇨🇿 Czech Republic</option>
                                <option value="DK">🇩🇰 Denmark</option>
                                <option value="DJ">🇩🇯 Djibouti</option>
                                <option value="DM">🇩🇲 Dominica</option>
                                <option value="DO">🇩🇴 Dominican Republic</option>
                                <option value="EC">🇪🇨 Ecuador</option>
                                <option value="EG">🇪🇬 Egypt</option>
                                <option value="SV">🇸🇻 El Salvador</option>
                                <option value="GQ">🇬🇶 Equatorial Guinea</option>
                                <option value="ER">🇪🇷 Eritrea</option>
                                <option value="EE">🇪🇪 Estonia</option>
                                <option value="ET">🇪🇹 Ethiopia</option>
                                <option value="FK">🇫🇰 Falkland Islands (Malvinas)</option>
                                <option value="FO">🇫🇴 Faroe Islands</option>
                                <option value="FJ">🇫🇯 Fiji</option>
                                <option value="FI">🇫🇮 Finland</option>
                                <option value="GF">🇬🇫 French Guiana</option>
                                <option value="PF">🇵🇫 French Polynesia</option>
                                <option value="TF">🇹🇫 French Southern Territories</option>
                                <option value="GA">🇬🇦 Gabon</option>
                                <option value="GM">🇬🇲 Gambia</option>
                                <option value="GE">🇬🇪 Georgia</option>
                                <option value="GH">🇬🇭 Ghana</option>
                                <option value="GI">🇬🇮 Gibraltar</option>
                                <option value="GR">🇬🇷 Greece</option>
                                <option value="GL">🇬🇱 Greenland</option>
                                <option value="GD">🇬🇩 Grenada</option>
                                <option value="GP">🇬🇵 Guadeloupe</option>
                                <option value="GU">🇬🇺 Guam</option>
                                <option value="GT">🇬🇹 Guatemala</option>
                                <option value="GG">🇬🇬 Guernsey</option>
                                <option value="GN">🇬🇳 Guinea</option>
                                <option value="GW">🇬🇼 Guinea-Bissau</option>
                                <option value="GY">🇬🇾 Guyana</option>
                                <option value="HT">🇭🇹 Haiti</option>
                                <option value="HM">🇦🇺 Heard Island and Mcdonald Islands</option>
                                <option value="HN">🇭🇳 Honduras</option>
                                <option value="HK">🇭🇰 Hong Kong</option>
                                <option value="HU">🇭🇺 Hungary</option>
                                <option value="IS">🇮🇸 Iceland</option>
                                <option value="IN">🇮🇳 India</option>
                                <option value="ID">🇮🇩 Indonesia</option>
                                <option value="IR">🇮🇷 Iran</option>
                                <option value="IQ">🇮🇶 Iraq</option>
                                <option value="IE">🇮🇪 Ireland</option>
                                <option value="IM">🇮🇲 Isle of Man</option>
                                <option value="IL">🇮🇱 Israel</option>
                                <option value="JM">🇯🇲 Jamaica</option>
                                <option value="JP">🇯🇵 Japan</option>
                                <option value="JE">🇯🇪 Jersey</option>
                                <option value="JO">🇯🇴 Jordan</option>
                                <option value="KZ">🇰🇿 Kazakhstan</option>
                                <option value="KE">🇰🇪 Kenya</option>
                                <option value="KI">🇰🇮 Kiribati</option>
                                <option value="KP">🇰🇵 Norh Korea</option>
                                <option value="KR">🇰🇷 South Korea</option>
                                <option value="XK">🇽🇰 Kosovo</option>
                                <option value="KW">🇰🇼 Kuwait</option>
                                <option value="KG">🇰🇬 Kyrgyzstan</option>
                                <option value="LA">🇱🇦 Laos</option>
                                <option value="LV">🇱🇻 Latvia</option>
                                <option value="LB">🇱🇧 Lebanon</option>
                                <option value="LS">🇱🇸 Lesotho</option>
                                <option value="LR">🇱🇷 Liberia</option>
                                <option value="LY">🇱🇾 Libyan Arab Jamahiriya</option>
                                <option value="LI">🇱🇮 Liechtenstein</option>
                                <option value="LT">🇱🇹 Lithuania</option>
                                <option value="LU">🇱🇺 Luxembourg</option>
                                <option value="MO">🇲🇴 Macao</option>
                                <option value="MK">🇲🇰 Macedonia</option>
                                <option value="MG">🇲🇬 Madagascar</option>
                                <option value="MW">🇲🇼 Malawi</option>
                                <option value="MY">🇲🇾 Malaysia</option>
                                <option value="MV">🇲🇻 Maldives</option>
                                <option value="ML">🇲🇱 Mali</option>
                                <option value="MT">🇲🇹 Malta</option>
                                <option value="MH">🇲🇭 Marshall Islands</option>
                                <option value="MQ">🇲🇶 Martinique</option>
                                <option value="MR">🇲🇷 Mauritania</option>
                                <option value="MU">🇲🇺 Mauritius</option>
                                <option value="YT">🇾🇹 Mayotte</option>
                                <option value="MX">🇲🇽 Mexico</option>
                                <option value="FM">🇫🇲 Micronesia</option>
                                <option value="MD">🇲🇩 Moldova</option>
                                <option value="MC">🇲🇨 Monaco</option>
                                <option value="MN">🇲🇳 Mongolia</option>
                                <option value="ME">🇲🇪 Montenegro</option>
                                <option value="MS">🇲🇸 Montserrat</option>
                                <option value="MA">🇲🇦 Morocco</option>
                                <option value="MZ">🇲🇿 Mozambique</option>
                                <option value="MM">🇲🇲 Myanmar</option>
                                <option value="NA">🇳🇦 Namibia</option>
                                <option value="NR">🇳🇷 Nauru</option>
                                <option value="NP">🇳🇵 Nepal</option>
                                <option value="NL">🇳🇱 Netherlands</option> 
                                <option value="AN">🇳🇱 Netherlands Antilles</option>
                                <option value="NC">🇳🇨 New Caledonia</option>
                                <option value="NZ">🇳🇿 New Zealand</option>
                                <option value="NI">🇳🇮 Nicaragua</option>
                                <option value="NE">🇳🇪 Niger</option>
                                <option value="NG">🇳🇬 Nigeria</option>
                                <option value="NU">🇳🇺 Niue</option>
                                <option value="NF">🇳🇫 Norfolk Island</option>
                                <option value="MP">🇲🇵 Northern Mariana Islands</option>
                                <option value="NO">🇳🇴 Norway</option>
                                <option value="OM">🇴🇲 Oman</option>
                                <option value="PK">🇵🇰 Pakistan</option>
                                <option value="PW">🇵🇼 Palau</option>
                                <option value="PS">🇵🇸 Palestinian Territory, Occupied</option>
                                <option value="PA">🇵🇦 Panama</option>
                                <option value="PG">🇵🇬 Papua New Guinea</option>
                                <option value="PY">🇵🇾 Paraguay</option>
                                <option value="PE">🇵🇪 Peru</option>
                                <option value="PH">🇵🇭 Philippines</option>
                                <option value="PN">🇵🇳 Pitcairn</option>
                                <option value="PL">🇵🇱 Poland</option>
                                <option value="PT">🇵🇹 Portugal</option>
                                <option value="PR">🇵🇷 Puerto Rico</option>
                                <option value="QA">🇶🇦 Qatar</option>
                                <option value="RE">🇷🇪 Reunion</option>
                                <option value="RO">🇷🇴 Romania</option>
                                <option value="RU">🇷🇺 Russian Federation</option>
                                <option value="RW">🇷🇼 Rwanda</option>
                                <option value="BL">🇧🇱 Saint Barthelemy</option>
                                <option value="SH">🇸🇭 Saint Helena</option>
                                <option value="KN">🇰🇳 Saint Kitts and Nevis</option>
                                <option value="LC">🇱🇨 Saint Lucia</option>
                                <option value="MF">🇫🇷 Saint Martin</option>
                                <option value="PM">🇵🇲 Saint Pierre and Miquelon</option>
                                <option value="VC">🇻🇨 Saint Vincent and the Grenadines</option>
                                <option value="WS">🇼🇸 Samoa</option>
                                <option value="SM">🇸🇲 San Marino</option>
                                <option value="ST">🇸🇹 Sao Tome and Principe</option>
                                <option value="SA">🇸🇦 Saudi Arabia</option>
                                <option value="SN">🇸🇳 Senegal</option>
                                <option value="RS">🇷🇸 Serbia</option>
                                <option value="CS">🇲🇪 Montenegro</option>
                                <option value="SC">🇸🇨 Seychelles</option>
                                <option value="SL">🇸🇱 Sierra Leone</option>
                                <option value="SG">🇸🇬 Singapore</option>
                                <option value="SX">🇸🇽 Sint Maarten</option>
                                <option value="SK">🇸🇰 Slovakia</option>
                                <option value="SI">🇸🇮 Slovenia</option>
                                <option value="SB">🇸🇧 Solomon Islands</option>
                                <option value="SO">🇸🇴 Somalia</option>
                                <option value="ZA">🇿🇦 South Africa</option>
                                <option value="GS">🇬🇸 South Georgia and the South Sandwich Islands</option>
                                <option value="SS">🇸🇸 South Sudan</option>
                                <option value="ES">🇪🇸 Spain</option>
                                <option value="LK">🇱🇰 Sri Lanka</option>
                                <option value="SD">🇸🇩 Sudan</option>
                                <option value="SR">🇸🇷 Suriname</option>
                                <option value="SJ">🇳🇴 Svalbard and Jan Mayen</option>
                                <option value="SZ">🇸🇿 Swaziland</option>
                                <option value="SE">🇸🇪 Sweden</option>
                                <option value="SY">🇸🇾 Syrian Arab Republic</option>
                                <option value="TW">🇹🇼 Taiwan</option>
                                <option value="TJ">🇹🇯 Tajikistan</option>
                                <option value="TZ">🇹🇿 Tanzania</option>
                                <option value="TH">🇹🇭 Thailand</option>
                                <option value="TL">🇹🇱 Timor-Leste</option>
                                <option value="TG">🇹🇬 Togo</option>
                                <option value="TK">🇹🇰 Tokelau</option>
                                <option value="TO">🇹🇴 Tonga</option>
                                <option value="TT">🇹🇹 Trinidad and Tobago</option>
                                <option value="TN">🇹🇳 Tunisia</option>
                                <option value="TR">🇹🇷 Turkey</option>
                                <option value="TM">🇹🇲 Turkmenistan</option>
                                <option value="TC">🇹🇨 Turks and Caicos Islands</option>
                                <option value="TV">🇹🇻 Tuvalu</option>
                                <option value="UG">🇺🇬 Uganda</option>
                                <option value="UA">🇺🇦 Ukraine</option>
                                <option value="AE">🇦🇪 United Arab Emirates</option>
                                <option value="GB">🇬🇧 United Kingdom</option>
                                <option value="US">🇺🇸 United States</option>
                                <option value="UY">🇺🇾 Uruguay</option>
                                <option value="UZ">🇺🇿 Uzbekistan</option>
                                <option value="VU">🇻🇺 Vanuatu</option>
                                <option value="VA">🇻🇦 Vatican City State</option>
                                <option value="VE">🇻🇪 Venezuela</option>
                                <option value="VN">🇻🇳 Viet Nam</option>
                                <option value="VG">🇻🇬 Virgin Islands, British</option>
                                <option value="VI">🇻🇮 Virgin Islands, U.s.</option>
                                <option value="WF">🇼🇫 Wallis and Futuna</option>
                                <option value="EH">🇪🇭 Western Sahara</option>
                                <option value="YE">🇾🇪 Yemen</option>
                                <option value="ZM">🇿🇲 Zambia</option>
                                <option value="ZW">🇿🇼 Zimbabwe</option>
                            </select>
                            <div className='mb-3'>
                              <input type='text' inputMode="tel" className={`form-control  ${formErrors.phone ? 'text-danger border-danger' : ''}`} name='phone' id='phone' placeholder='Telefon' onBlur={handleBlur} onChange={handleFormChange}></input> 
                              {formErrors.phone && <small className="text-danger">{formErrors.phone}</small>}
                            </div>
                            <div className='mb-3'>
                              <input type='text' inputMode="email" className={`form-control  ${formErrors.email ? 'text-danger border-danger' : ''}`} name='email' id='email' placeholder='E-Mail Adresse' onBlur={handleBlur} onChange={handleFormChange}></input>
                              {formErrors.email && <small className="text-danger">{formErrors.email}</small>}
                            </div>
                            <div className='mb-3'>
                              <input type='text' inputMode="url" className={`form-control  ${formErrors.homepage ? 'text-danger border-danger' : ''}`} name='homepage'  id='homepage' placeholder='Homepage' onBlur={handleBlur} onChange={handleFormChange}></input>
                              {formErrors.homepage && <small className="text-danger">{formErrors.homepage}</small>}
                            </div>

                            
                            <div className='mb-3'>
                                <label htmlFor="formFileLg" className="form-label">Titelbild *</label>
                                <input className="form-control form-control-lg" accept=".png, .jpg, .jpeg"  id="formFileLg" name='thumbnail' type="file" onChange={handleFormChange}></input>
                            </div>


                            <div className='mb-5'>
                                <label htmlFor="formFileLg2" className="form-label">Weitere Bilder</label>
                                <input className="form-control form-control-lg" accept=".png, .jpg, .jpeg"  id="formFileLg2" name='otherImage' type="file" multiple onChange={handleFormChange}></input>
                                <small >Maximum 10 Bilder</small>
                            </div>     
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12'>
                      
                      {chargerType.map((chargertype) => {
                        return (
                          <ChargerType name={chargertype.typeName} icon={chargertype.icon} key={chargertype._id} id={chargertype._id} onChange={handleFormChange}/>                                 
                        )
                      })}
                      <div className="form-check form-switch mb-3">
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Standgebühren/Strafgebühren? </label>
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name='standingFee' onChange={handleFormChange}></input>
                      </div>          
                    </div>
                </div>
                <div className='row'>
                    
                    {/* Comment 
                    <div className='col-12'>
                       
                      <div className="form-check form-switch hidden mb-3">
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Review erfassen </label>
                          <input className="form-check-input" type="checkbox" role="switch" name='reviewVisible' id="flexSwitchCheckDefault" onChange={() => setReviewVisible(!isReviewVisible)} checked={isReviewVisible} ></input>
                      </div>
                      
                      <div className={`ratingSection ${isReviewVisible ? '' : 'hide'}`}>
                          <StarRating initialRating={rating} onRatingChange={handleRatingChange} />
                          <textarea className='form-control' rows={5} placeholder='Bewertung'>

                          </textarea>
                      </div>
                    </div>
                    */} 
                </div>
                <button type="submit" className="btn btn-primary mb-5">Unterkunft hinzufügen</button>
              </form>
          </div>
      </div>
    </div>
  )
}

export default AddHotel